/**
 * 配置浏览器本地存储的方式，可直接存储对象数组。
 */

import { Storage } from '@ionic/storage';

const store = new Storage();

(async () => {
  await store.create();
})();
export const useCache = () => {
  return {
    wsCache: store
  }
}