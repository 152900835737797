import request from '@/config/axios'
import type { TableData } from './types'

export const listApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({
    url: `/studentTests/list`,
    params
  })
  return res
}

export const syncApi = async (data: Partial<TableData>): Promise<IResponse> => {
  return await request.post({ url: '/studentTests/sync', data })
}

export const updateApi = async (data: Partial<TableData>): Promise<IResponse> => {
  return await request.put({ url: `/studentTests/${data.id}`, data })
}

export default {
  syncApi,
  listApi,
  updateApi
}
