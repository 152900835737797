<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" v-show="showTab">
        <ion-tab-button tab="tab1" href="/tabs/tab1">
          <ion-icon :icon="listSharp" />
          <ion-label>總表</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/tab2">
          <ion-badge color="danger" v-if="notificationCount > 0">{{ notificationCount }}</ion-badge>
          <ion-icon aria-hidden="true" src="/assets/images/svg/tools_icon_feed.svg" />
          <ion-label>學生近況</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/tab3/absent">
          <ion-icon aria-hidden="true" src="/assets/images/svg/tools_icon_otherclass.svg" />
          <ion-label>課程</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/tabs/tab4">
          <ion-icon aria-hidden="true" src="/assets/images/svg/tools_icon_class.svg" />
          <ion-label>我的班級</ion-label>
        </ion-tab-button>

        <!-- <ion-tab-button tab="tab2" href="/tabs/tab3/tab2">
          <ion-icon aria-hidden="true" src="/assets/images/svg/tools_icon_otherclass.svg" />
          <ion-label>課堂回報表</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/tab3/tab2">
          <ion-icon aria-hidden="true" src="/assets/images/svg/tools_icon_otherclass.svg" />
          <ion-label>未繳費名單</ion-label>
        </ion-tab-button> -->

        <ion-tab-button tab="tab5" href="/tabs/tab5">
          <ion-icon aria-hidden="true" src="/assets/images/svg/tools_icon_setting.svg" />
          <ion-label>設定</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import api from '@/api';
import { useRoute } from 'vue-router';
import { listSharp } from 'ionicons/icons';
const route = useRoute()
const notificationCount = ref(0)

const showTab = ref(true)

watch(() => route.fullPath, async (val) => {
  notificationCount.value = await api.notification.getNotificationCount()
  
  showTab.value = (val !== '/tabs/tab1')

}, { immediate: true })

</script>

<style>
ion-tab-button {
  ion-icon {
    font-size: 32px;
  }

  ion-label {
    font-size: 12px;
  }
}

ion-tab-bar {
  height: 70px;
}

</style>