import request from '@/config/axios'
import { formatTime } from '@/utils'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/tutorialRollCalls', params })

  return {
    data: res.data.map((x) => {
      x.time = formatTime(x.time, 'yyyy-MM-dd HH:mm')
      return x
    }),
    meta: res.meta
  }
}

export const saveApi = async (data: Partial): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/tutorialRollCalls/${data.id}`, data })
  } else {
    return await request.post({ url: '/tutorialRollCalls', data })
  }
}

export const getApi = async (id: string): Promise<TableData> => {
  const res = await request.get({ url: `/tutorialRollCalls/${id}` })
  return res
}

export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/tutorialRollCalls`, params: { ids } })
}

export default {
  getListApi,
  saveApi,
  delApi,
  getApi
}
