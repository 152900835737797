import request from '@/config/axios'

export const listApi = ({
  class_id,
  class_roll_call_id = undefined,
  student_test_ids = undefined
}) => {
  return async (params: any): Promise<IResponse> => {
    const res = await request.get({
      url: `/studentTestGrades/list`,
      params: { ...params, class_id, class_roll_call_id, student_test_ids }
    })
    return res
  }
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/studentTestGrades/${data.id}`, data })
  } else {
    return await request.post({ url: '/studentTestGrades', data })
  }
}

export default {
  saveApi,
  listApi
}
