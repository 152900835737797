import request from '@/config/axios'

interface RoleParams {
  roleName: string
}

export const loginApi = (data: any): Promise<IResponse<any>> => {
  return request.post({ url: '/user/login', data })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.post({ url: '/user/logout' })
}

export const changePasswordApi = (data: UserChangePasswordType): Promise<IResponse> => {
  return request.post({ url: '/user/changePassword', data })
}
export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    code: string
    data: {
      list: any[]
      total: number
    }
  }>({ url: '/user/list', params })
}

export const getRouteApi = (): string[] => {
  return [
    '/myclass/tutorial-rollcall',
    '/myclass/tutorial-rollcall-tutorialAttend',
    '/myclass/tutorial-rollcall-studentStatus',
    '/myclass/tutorial-rollcall-studentStatus-edit',
    '/myclass',
    '/myclass/class',
    '/myclass/class-edit',
    '/myclass/studentList',
    '/myclass/class-student',
    '/myclass/class-student-edit',
    '/myclass/class-student-import',
    '/myclass/class-rollcall',
    '/myclass/class-rollcall-studentAttend',
    '/myclass/class-rollcall-studentGrade',
    '/myclass/class-rollcall-studentStatus',
    '/myclass/class-rollcall-studentStatus-edit',
    '/myclass/payment',
    '/myclass/payment-edit',
    '/myclass/attend-record',
    '/myclass/payment-unpaid',

    '/otherclass',
    '/otherclass/tutorial-rollcall',
    '/otherclass/class',
    '/otherclass/studentList',
    '/otherclass/class-student',
    '/otherclass/class-student-edit',
    '/otherclass/class-rollcall',
    '/otherclass/class-rollcall-studentAttend',
    '/otherclass/class-rollcall-studentGrade',
    '/otherclass/class-rollcall-studentStatus',
    '/otherclass/class-rollcall-studentStatus-edit',
    '/otherclass/tutorial-rollcall-tutorialAttend',
    '/otherclass/tutorial-rollcall-studentStatus',
    '/otherclass/tutorial-rollcall-studentStatus-edit',
    '/otherclass/attend-record',
    '/otherclass/payment-unpaid',

    '/error',
    '/error/404-demo',
    '/error/403-demo',
    '/error/500-demo'
  ]
  // .concat(getAdminRoleApi())
}

export const getAdminRoleApi = (): string[] => {
  return [
    '/manage',
    '/manage/teacher',
    '/manage/teacher-edit',
    '/manage/class',
    '/manage/class-edit',
    '/manage/student-change-detail',
    '/manage/payment',
    '/manage/payment-edit',
    '/manage/payment-record',
    '/manage/payment-unpaid'
  ]
}

export default {
  loginApi,
  loginOutApi,
  changePasswordApi,
  getUserListApi,
  getRouteApi,
  getAdminRoleApi
}

