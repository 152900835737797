import { defineStore } from 'pinia'
import { store } from '../index'
import { useCache } from '@/hooks/web/useCache'
const { wsCache } = useCache()

interface AppState {
  fixedMenu: boolean
  userInfo: any
  clickStatus: boolean
  summaryTable: any

}
const fixedMenu = await wsCache.get('fixedMenu')
const userInfo = await wsCache.get('userInfo')
const summaryTable = await wsCache.get('summaryTable')



export const useAppStore = defineStore('app', {
  state: (): AppState => {
    return {
      fixedMenu: fixedMenu || false, // 是否固定菜单
      userInfo: userInfo || {}, // 登录信息存储字段-建议每个项目换一个字段，避免与其他项目冲突
      clickStatus: false, // 是否点击
      summaryTable: summaryTable || {}

    }
  },
  getters: {
    getFixedMenu(): boolean {
      return this.fixedMenu
    },
    getUserInfo(): any {
      return this.userInfo
    },
    getClickStatus(): boolean {
      return this.clickStatus
    },
    getSummaryTable(): any {
      return this.summaryTable
    }
  },
  actions: {
    async setFixedMenu(fixedMenu: boolean) {
      await wsCache.set('fixedMenu', fixedMenu)
      this.fixedMenu = fixedMenu
    },
    async setUserInfo(userInfo: any) {
      await wsCache.set('userInfo', userInfo)
      this.userInfo = userInfo
    },
    setClickStatus(clickStatus: boolean) {
      this.clickStatus = clickStatus
    },
    async setSummaryTable(summaryTable: any) {
      await wsCache.set('summaryTable', summaryTable)
      this.summaryTable = summaryTable
    }
  }
})

export const useAppStoreWithOut = () => {
  return useAppStore(store)
}