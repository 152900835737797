import request from '@/config/axios'

export const getListApi = async (params: any): Promise<IResponse> => {
  return await request.get({ url: '/studentStatuses', params })
}

export const getClassAweekApi = async (params: any): Promise<IResponse> => {
  return await request.get({ url: '/studentStatuses/classAweek', params })
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/studentStatuses/${data.id}`, data })
  } else {
    return await request.post({ url: '/studentStatuses', data })
  }
}

export const getApi = async (id: string): Promise<TableData> => {
  return await request.get({ url: `/studentStatuses/${id}` })
}

export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/studentStatuses`, params: { ids } })
}

export function getStatusList() {
  return [
    { label: '一般', value: '一般' },
    { label: '新生', value: '新生' },
    { label: '線上', value: '線上' },
    { label: '進步鼓勵', value: '進步鼓勵' },
    { label: '校小考不佳', value: '校小考不佳' },
    { label: '週考不佳', value: '週考不佳' },
    { label: '段考不佳', value: '段考不佳' },
    { label: '危險名單', value: '危險名單' },
    { label: '作業未完成', value: '作業未完成' },
    { label: '缺課學生', value: '缺課學生' }
  ]
}

export default {
  getListApi,
  getClassAweekApi,
  saveApi,
  getApi,
  delApi,
  getStatusList
}
