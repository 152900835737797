import request from '@/config/axios'

export const listApi = ({ tutorial_roll_call_id }) => {
  return async (params: any): Promise<IResponse> => {
    const res = await request.get({
      url: `/tutorialAttends/list`,
      params: { ...params, tutorial_roll_call_id }
    })
    return res
  }
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/tutorialAttends/${data.id}`, data })
  } else {
    return await request.post({ url: '/tutorialAttends', data })
  }
}

export default {
  saveApi,
  listApi
}
