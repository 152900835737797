import request from '@/config/axios'
import { formatTime } from '@/utils'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/notifications', params: { ...params } })

  const changeColumn = (x) => {
    x.created_at = formatTime(x.created_at, 'yyyy-MM-dd HH:mm')
    return x
  }

  if (params.page) {
    res.data = res.data?.map(changeColumn)
    return res.data
  } else {
    return res.map(changeColumn)
  }
}

export const getNotificationCount = async (): Promise<IResponse> => {
  const result = await request.post({ url: '/notifications/getNotificationCount' })
  return result.data
}

export const readOne = async ({ notification_id }): Promise<IResponse> => {
  const result = await request.post({ url: '/notifications/readOne', data: { notification_id } })
  return result.data
}

export const readAll = async () => {
  const result = await request.post({ url: '/notifications/readAll' })
  return result.data
}

export default {
  getListApi,
  getNotificationCount,
  readOne,
  readAll
}
